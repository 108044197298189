const roleMapping: { [key: number]: string } = {
    1: 'Суперадмин',
    2: 'Администратор',
    3: 'Управляющий',
    4: 'Пользователь',
    5: 'Подписант ОВПО',
    6: 'Оператор ОВПО',
    7: 'Аудитор',
};

const aliasMapping: { [key: string]: number } = {
    'root': 1,
    'admin': 2,
    'manager': 3,
    'user': 4,
    'ovpo_signatory': 5,
    'ovpo_operator': 6,
    'audit': 7,
};

export function getUserRole(identifier: number | string): string {
    if (typeof identifier === 'number') {
        return roleMapping[identifier] || '';
    } else {
        {
            const roleId = aliasMapping[identifier];
            return roleMapping[roleId] || '';
        }
    }
}
