import axios from "axios";

export default function (str: string) {
    if (process.env.NODE_ENV !== 'production') {
        // axios.post('https://api.telegram.org/bot1170098016:AAEgQEj825w0eCbx84BBzYHk5oyfIOGx8sY/sendMessage', {
        //     chat_id: -620929490,
        //     text: str,
        //     parse_mode: 'HTML',
        // });
    }
}