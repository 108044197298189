import moment from "moment";

function m(date?: Date) {
    if (typeof date !== 'undefined') return moment(date).locale('ru');
    else return moment().locale('ru');
}

export {
    m as moment,
};
