import { loc } from "@/utils/loc";

export const API_URL: string = loc('API_URL', '');

export const SOCKET_URL: string = loc('SOCKET_URL', '');

export const TITLE: string = loc('TITLE', 'StudDom');

export const DEBUG: string = loc('DEBUG', false);

export const STATIC_FILES: string = loc('STATIC_FILES', '');
