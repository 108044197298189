import local from "@/config.local";

export const loc = (key: string, value: any) => {
    let path = key.split('.');

    let _value = path.reduce((r: any, c: any) => {
        if (r !== null && r.hasOwnProperty(c)) {
            r = r[c];
        } else {
            r = null;
        }

        return r;
    }, local);

    if (_value) return _value;

    return value;
};